<template>
  <div>
    <GraphsMenu menu="work-pending" type="customer"></GraphsMenu><br />
    <CCard>
    <CCardHeader>
      <slot name="header">
        Trabajo Pendiente
        <div class="card-header-actions" style="width: 800px; margin-right: 18px;">
          <CRow>      
            <CCol md="4" style="text-align: right;">
              <CSelect class="m-0"
                :value.sync="monthSelect" 
                :options="monthsOptions"
                @change="changeFilters"
              />  
            </CCol> 
            <CCol md="8" style="text-align: right; padding-right: 0px;">
              <CSelect class="m-0" style="float: right; width: 100%;"
                :value.sync="collaboratorSelect" 
                :options="collaboratorsOptions"
                @change="changeFilters"
              />
            </CCol>
          </CRow>
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CRow>      
        <CCol md="3">
          <CCard>                        
            <CCardBody style="height: 650px;">
              <CRow> 
                <CCol md="12" class="text-center">    
                  <div style="font-size: 16px;"><strong>Visitas del Mes</strong></div>
                  <div  style="font-size: 12px;">{{metrics.month_visits_range}}</div>              
                  <br />
                </CCol>      
                <CCol md="4">
                  <CWidgetSimple style="height: 90px;" class="bland">
                    <div class="m-0" style="font-size: 12px;">Total</div>
                    <div style="margin-top: 12px; font-weight: bold; font-size: 12px;">{{metrics.month_visits_total}}</div>          
                  </CWidgetSimple>
                </CCol>       
                <CCol md="4">
                  <CWidgetSimple style="height: 90px;" class="success">
                    <div class="m-0" style=" font-size: 12px;">Realizadas</div>
                    <div style="margin-top: 12px; font-weight: bold;">{{metrics.month_visits_done}}</div>          
                  </CWidgetSimple>
                </CCol>   
                <CCol md="4">
                  <CWidgetSimple style="height: 90px;" class="bland">
                    <div class="m-0" style=" font-size: 12px;">Faltantes</div>
                    <div style="margin-top: 12px; font-weight: bold;">{{metrics.month_visits_left}}</div>          
                  </CWidgetSimple>
                </CCol>       
              </CRow>
              <GaugePercentSemaforoGraph ref="gaugeMonthVisits" title="Porcentaje"></GaugePercentSemaforoGraph>
            </CCardBody> 
          </CCard>
        </CCol>
        <CCol md="3">
          <CCard>                        
            <CCardBody style="height: 650px;">  
              <CRow> 
                <CCol md="12" class="text-center">    
                  <div  style="font-size: 16px;"><strong>Meta del Mes</strong></div>       
                  <div  style="font-size: 12px;">{{metrics.month_times_range}}</div>
                  <br />       
                </CCol>      
                <CCol md="4">
                  <CWidgetSimple style="height: 90px;" class="bland">
                    <div class="m-0" style="font-size: 12px;">Promedio</div>
                    <div style="margin-top: 12px; font-weight: bold;font-size: 12px;">{{metrics.month_time_total}} Min</div>          
                  </CWidgetSimple>
                </CCol>       
                <CCol md="4">
                  <CWidgetSimple style="height: 90px;" class="bland">
                    <div class="m-0" style="font-size: 12px;">Meta</div>
                    <div style="margin-top: 12px; font-weight: bold;font-size: 12px;">{{metrics.month_time_limit}} Min</div>          
                  </CWidgetSimple>
                </CCol>   
                <CCol md="4">
                  <CWidgetSimple style="height: 90px;" class="bland">
                    <div class="m-0" style="font-size: 12px;">Diferencia</div>
                    <div style="margin-top: 12px; font-weight: bold;font-size: 12px;">{{metrics.month_time_diff}} Min</div>          
                  </CWidgetSimple>
                </CCol>       
              </CRow>
              <GaugePercentSemaforoGraph ref="gaugeMonthTimes" title="Promedio" valueTp="0"></GaugePercentSemaforoGraph>    
            </CCardBody> 
          </CCard>  
        </CCol>
        <CCol md="3" v-show="metrics.type == 'current'">
          <CCard>                        
            <CCardBody style="height: 650px;">
              <CRow> 
                <CCol md="12" class="text-center">    
                  <div style="font-size: 16px;"><strong>Visitas de la Semana</strong></div>  
                  <div  style="font-size: 12px;">{{metrics.week_visits_range}}</div>            
                  <br />
                </CCol>      
                <CCol md="4">
                  <CWidgetSimple style="height: 90px;" class="bland">
                    <div class="m-0" style="font-size: 12px;">Total</div>
                    <div style="margin-top: 12px; font-weight: bold; font-size: 12px;">{{metrics.week_visits_total}}</div>          
                  </CWidgetSimple>
                </CCol>       
                <CCol md="4">
                  <CWidgetSimple style="height: 90px;" class="success">
                    <div class="m-0" style=" font-size: 12px;">Realizadas</div>
                    <div style="margin-top: 12px; font-weight: bold;">{{metrics.week_visits_done}}</div>          
                  </CWidgetSimple>
                </CCol>   
                <CCol md="4">
                  <CWidgetSimple style="height: 90px;" class="bland">
                    <div class="m-0" style=" font-size: 12px;">Faltantes</div>
                    <div style="margin-top: 12px; font-weight: bold;">{{metrics.week_visits_left}}</div>          
                  </CWidgetSimple>
                </CCol>       
              </CRow>
              <GaugePercentSemaforoGraph ref="gaugeWeekVisits" title="Porcentaje"></GaugePercentSemaforoGraph>
            </CCardBody> 
          </CCard>
        </CCol>
        <CCol md="3" v-show="metrics.type == 'current'">
          <CCard>                        
            <CCardBody style="height: 650px;">  
              <CRow> 
                <CCol md="12" class="text-center">    
                  <div  style="font-size: 16px;"><strong>Meta de la Semana</strong></div>  
                  <div  style="font-size: 12px;">{{metrics.week_times_range}}</div>
                  <br />   
                </CCol>      
                <CCol md="4">
                  <CWidgetSimple style="height: 90px;" class="bland">
                    <div class="m-0" style="font-size: 12px;">Promedio</div>
                    <div style="margin-top: 12px; font-weight: bold;font-size: 12px;">{{metrics.week_time_total}} Min</div>          
                  </CWidgetSimple>
                </CCol>       
                <CCol md="4">
                  <CWidgetSimple style="height: 90px;" class="bland">
                    <div class="m-0" style="font-size: 12px;">Meta</div>
                    <div style="margin-top: 12px; font-weight: bold;font-size: 12px;">{{metrics.week_time_limit}} Min</div>          
                  </CWidgetSimple>
                </CCol>   
                <CCol md="4">
                  <CWidgetSimple style="height: 90px;" class="bland">
                    <div class="m-0" style="font-size: 12px;">Diferencia</div>
                    <div style="margin-top: 12px; font-weight: bold;font-size: 12px;">{{metrics.week_time_diff}} Min</div>          
                  </CWidgetSimple>
                </CCol>       
              </CRow>
              <GaugePercentSemaforoGraph ref="gaugeWeekTimes" title="Promedio" valueTp="0"></GaugePercentSemaforoGraph>    
            </CCardBody> 
          </CCard>  
        </CCol>
        <CCol md="3" v-show="metrics.type == 'others'">
          <CCard>                        
            <CCardBody style="height: 650px;">
              <CRow> 
                <CCol md="12" class="text-center">    
                  <div style="font-size: 16px;"><strong>Visitas del Mes</strong></div>
                  <div  style="font-size: 12px;">{{metrics.month2_visits_range}}</div>              
                  <br />
                </CCol>      
                <CCol md="4">
                  <CWidgetSimple style="height: 90px;" class="bland">
                    <div class="m-0" style="font-size: 12px;">Total</div>
                    <div style="margin-top: 12px; font-weight: bold; font-size: 12px;">{{metrics.month2_visits_total}}</div>          
                  </CWidgetSimple>
                </CCol>       
                <CCol md="4">
                  <CWidgetSimple style="height: 90px;" class="success">
                    <div class="m-0" style=" font-size: 12px;">Realizadas</div>
                    <div style="margin-top: 12px; font-weight: bold;">{{metrics.month2_visits_done}}</div>          
                  </CWidgetSimple>
                </CCol>   
                <CCol md="4">
                  <CWidgetSimple style="height: 90px;" class="bland">
                    <div class="m-0" style=" font-size: 12px;">Faltantes</div>
                    <div style="margin-top: 12px; font-weight: bold;">{{metrics.month2_visits_left}}</div>          
                  </CWidgetSimple>
                </CCol>       
              </CRow>
              <GaugePercentSemaforoGraph ref="gaugeMonth2Visits" title="Porcentaje"></GaugePercentSemaforoGraph>
            </CCardBody> 
          </CCard>
        </CCol>
        <CCol md="3" v-show="metrics.type == 'others'">
          <CCard>                        
            <CCardBody style="height: 650px;">  
              <CRow> 
                <CCol md="12" class="text-center">    
                  <div  style="font-size: 16px;"><strong>Meta del Mes</strong></div>       
                  <div  style="font-size: 12px;">{{metrics.month2_times_range}}</div>
                  <br />       
                </CCol>      
                <CCol md="4">
                  <CWidgetSimple style="height: 90px;" class="bland">
                    <div class="m-0" style="font-size: 12px;">Promedio</div>
                    <div style="margin-top: 12px; font-weight: bold;font-size: 12px;">{{metrics.month2_time_total}} Min</div>          
                  </CWidgetSimple>
                </CCol>       
                <CCol md="4">
                  <CWidgetSimple style="height: 90px;" class="bland">
                    <div class="m-0" style="font-size: 12px;">Meta</div>
                    <div style="margin-top: 12px; font-weight: bold;font-size: 12px;">{{metrics.month2_time_limit}} Min</div>          
                  </CWidgetSimple>
                </CCol>   
                <CCol md="4">
                  <CWidgetSimple style="height: 90px;" class="bland">
                    <div class="m-0" style="font-size: 12px;">Diferencia</div>
                    <div style="margin-top: 12px; font-weight: bold;font-size: 12px;">{{metrics.month2_time_diff}} Min</div>          
                  </CWidgetSimple>
                </CCol>       
              </CRow>
              <GaugePercentSemaforoGraph ref="gaugeMonth2Times" title="Promedio" valueTp="0"></GaugePercentSemaforoGraph>    
            </CCardBody> 
          </CCard>  
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
  </div>
</template>
<script>

import store from '../../store'
import ws from '../../services/graphs';
import GaugePercentSemaforoGraph from './graphs/GaugePercentSemaforoPending.vue';
import GraphsMenu from './GraphsMenu.vue';

export default {
  name: 'CustomerGraphsHome2',
  components: {    
    GaugePercentSemaforoGraph,
    GraphsMenu
  },
  data () {
    return {
      fields: [
          {key: 'position', label: ''},
          {key: 'name', label: 'Colaborador'},
          {key: 'total', label: 'Total'},
        ],      
      collaboratorSelect: "",
      collaboratorsOptions: [        
      ],
      customerSelect: "",
      customersOptions: [        
      ],
      monthSelect: "202409",
      monthsOptions: [],
      dataGraphs: "",
      metrics:{
        month_visits_total: 0,
        month_visits_done: 0,
        month_visits_left: 0,
        month_visits_average: 0,
        month_time_total: 0,
        month_time_limit: 0,
        month_time_diff: 0,
        month_time_avg: 0,
        week_visits_total: 0,
        week_visits_done: 0,
        week_visits_left: 0,
        week_visits_average: 0,
        week_time_total: 0,
        week_time_limit: 0,
        week_time_diff: 0,
        week_time_avg: 0
      }
    }
  },
  mounted: async function() {
    this.loading();

    let is_customer = localStorage.getItem("is_customer");

    if(is_customer == "false"){
      let is_administrator = localStorage.getItem("is_administrator");

      if(is_administrator == "false"){
        let is_kam = localStorage.getItem("is_kam");

        if(is_kam == "false"){
          window.location.href = "/#/home"; this.loaded(); return;
        }
        else{
          window.location.href = "/#/welcome"; this.loaded(); return;
        }
      }
      else{
        window.location.href = "/#/graphs/general"; this.loaded(); return;
      }
    }

    this.customerSelect = localStorage.getItem("customer_id")*1;

    let response2 = await ws.getMonths(); 
    if(response2.type == "success"){
      this.monthsOptions = await this.$parseSelectOptions(response2.data, "id", "name");
      if(response2.data.length > 0){
        this.monthSelect = this.monthsOptions[0].value;
      }      

      let params = {month: this.monthsOptions[0].value};
      let response = await ws.getCustomers(); 
      if(response.type == "success"){
        this.customersOptions = await this.$parseSelectOptionsOrdered(response.data, "id", "name",[{"label": "Todos","value":""}]);
      }

      let params2 = {month: this.monthsOptions[0].value, customer_id: this.customersOptions[0].value};
      let response_colla = await ws.getCollaborators(params2); 
      if(response_colla.type == "success"){
        this.collaboratorsOptions = await this.$parseSelectOptionsOrdered(response_colla.data, "id", "name",[{"label": "Todos","value":""}]);
        if(response.data.length > 0){
          this.collaboratorSelect = this.collaboratorsOptions[0].value;
        }            
      }
    }

    await this.changeFilters();

    this.loaded();
  },
  methods: {
    async changeFilters () {
      this.loading();
        let filters = {
          customer_id : this.customerSelect,
          collaborator_id : this.collaboratorSelect,
          month : this.monthSelect,
        };
        let response_graphs = await ws.getGraphsPending(filters);
        if(response_graphs.type == "success"){
          this.dataGraphs = response_graphs.data;  
          this.metrics = response_graphs.data;
        }
        this.chargeGraphs();
      this.loaded();
    },
    async changeCustomers () {
      this.loading();
        let params = {month: this.monthSelect, customer_id: this.customerSelect};
        let response_colla = await ws.getCollaborators(params); 
        if(response_colla.type == "success"){
          this.collaboratorsOptions = await this.$parseSelectOptionsOrdered(response_colla.data, "id", "name",[{"label": "Todos","value":""}]);
          if(response_colla.data.length > 0){
            this.collaboratorSelect = this.collaboratorsOptions[0].value;
          }            
        }

        await this.changeFilters();
      this.loaded();
    },
    async chargeGraphs(){
        await this.$refs.gaugeMonthVisits.loadGraph(this.metrics.month_visits_average);
        await this.$refs.gaugeMonthTimes.loadGraph(this.metrics.month_time_avg);
        await this.$refs.gaugeWeekVisits.loadGraph(this.metrics.week_visits_average);
        await this.$refs.gaugeWeekTimes.loadGraph(this.metrics.week_time_avg);

        await this.$refs.gaugeMonth2Visits.loadGraph(this.metrics.month2_visits_average);
        await this.$refs.gaugeMonth2Times.loadGraph(this.metrics.month2_time_avg);
    },
    showToast(type, message){
        store.commit('toast', {color: type, message: message});
    },
    color (value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'danger'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'success'
      }
      return $color
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>

<style>
.warning{
 background-color: #F9B115;
}
.success{
  background-color: #1B9E3E;
}
.danger{
  background-color: #E55353
}
.other{
  background-color: #6b7785
}
.bland{
  background-color: #FFECB4;
}
.hard{
  background-color: #C5CAE8;
}
.card.success{
  color:black !important;
  font-weight: bold !important;
}
.card-header-actions select{
  font-size: 12px;
}
.card-header{
  font-size: 20px;
  padding: 10px 15px 10px 20px;

}
</style>
